<template>
  <v-card raised elevation="6" class="mt-1">
    <v-card-title>
      <h4
        style="
          font-size: 15px;
          text-transform: uppercase;
          font-weight: 100;
          text-decoration: underline;
        "
      >
        {{ $t("clients.byAge") }}
      </h4>
      <v-spacer></v-spacer>
      <v-btn @click="graphic = !graphic" :outlined="graphic" height="30px"
        >Tabla
      </v-btn>
    </v-card-title>
    <v-card-text v-if="graphic">
      <v-row>
        <v-col cols="8">
          <BarChartClientsByAge />
        </v-col>
        <v-col cols="4">
          <PieChartClientsByAge />
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      v-else
      :headers="headersClientsByAge"
      :items="list"
      :items-per-page="10"
      id="clientsAge-table"
    >
      <template v-slot:item.anyo="{ item }">
        {{ item.anyo }}
      </template>
      <template v-slot:item.cantidad="{ item }">
        {{ item.cantidad }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "ClientsByAge",
  components: {
    PieChartClientsByAge: () =>
      import("@/components/stats/clients/graphics/ClientsByAgeGraphicPie"),
    BarChartClientsByAge: () =>
      import("@/components/stats/clients/graphics/ClientsByAgeGraphicBar"),
  },
  ...mapState("stats", ["lists"]),

  computed: {
    //METODO / VARIABLE
    list() {
      return this.$store.state.stats.lists.clientsByAgeGroup;
    },
  },
  data() {
    return {
      graphic: true,
      headersClientsByAge: [
        {
          text: this.$t("clients.byAge"),
          align: "center",
          sortable: false,
          value: "anyo",
        },
        {
          text: this.$t("quantity"),
          align: "center",
          sortable: false,
          value: "cantidad",
        },
      ],
    };
  },
};
</script>
